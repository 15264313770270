import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const EventCard = ({ event }) => {
  return (
    <div className="sm:max-w-md overflow-hidden border-b border-gray-600">
      <Link to={event.fields.slug}>
        <EventImage image={event.frontmatter.image} />
        <div className="px-6 py-2">
          <div className="font-bold text-xl mb-2">
            {event.frontmatter.title}
          </div>
          <p className="text-gray-700 text-base">
            {event.frontmatter.name}
          </p>
        </div>
      </Link>
    </div>
  )
}

const EventImage = ({ image }) => {
  const imageStyle = {
    width: "100%",
    height: 200,
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const EventList = ({ data }) => {
  const { edges: events } = data.allMarkdownRemark
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {events &&
        events.map(({ node: event }, i) => (
          <EventCard key={event.id} event={event} />
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "event-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                name
                description
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EventList data={data} />}
  ></StaticQuery>
)
