import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import EventList from "../components/EventList"
import ThemeList from "../components/ThemeList"
import ProjectList from "../components/ProjectList"
import PartnerList from "../components/PartnerList"
// import PublicationList from "../components/PublicationList"

const IndexPage = ({ data }) => {
  return (
    <>
      <Layout>
        <div className="container mx-auto">
          {/* <h2 className="mt-10 mb-2 text-4xl font-bold">Events</h2>
          <EventList /> */}
          <h2 className="mt-10 mb-2 text-4xl font-bold">Themes</h2>
          <ThemeList />
          <h2 className="mt-10 mb-2 text-4xl font-bold">Projects</h2>
          <ProjectList />
          {/* <h2 className="mt-10 mb-2 text-4xl font-bold">Impact</h2>
          <PublicationList /> */}
          <h2 className="mt-10 mb-2 text-4xl font-bold">Partners</h2>
          <PartnerList />
        </div>
        {/* <div className="bg-gray-100 invisible sm:visible">sm</div>
        <div className="bg-gray-200 invisible md:visible">md</div>
        <div className="bg-gray-300 invisible lg:visible">lg</div>
        <div className="bg-gray-400 invisible xl:visible">xl</div> */}
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Index {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      id
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 50) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
